<template>
    <div class="info">
      <top-bar :title="'报修处理'" :left="true"></top-bar>

      <van-popup v-model="resultShow" position="bottom"  >
        <van-picker title="处理结果" show-toolbar :columns="resultOptions" value-key="label" @confirm="resultConfirm" @cancel="resultShow = false"  />
      </van-popup>
      <van-popup v-model="typeShow" position="bottom"  >
        <van-picker title="报修类别" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm" @cancel="typeShow = false"  />
      </van-popup>
      <van-popup v-model="solveShow" position="bottom"  >
        <van-picker title="推送到" show-toolbar :columns="solveList" value-key="label" @confirm="solveConfirm" @cancel="solveShow = false"  />
      </van-popup>

      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">报修详情</span>
          </template>
        </van-cell>
        <van-cell title="报修类别" :value="typeValue" @click="typeShow = !typeShow" is-link/>
        <van-cell title="处理结果" :value="resultValue" @click="resultShow = !resultShow" is-link/>
        <van-cell title="推送到其他人员" :value="solveValue" v-if="dataForm.status == 10" @click="solveShow = !solveShow"/>
        <van-field clearable clear-trigger="always" v-model="dataForm.solveCost" label="维修费用(元)" placeholder="请输入" input-align="right" v-if="dataForm.status == 20 || dataForm.status == 30"/>
        <van-field clearable clear-trigger="always" v-model="dataForm.solveDes" placeholder="请输入回复" :border="false" rows="3" type="textarea" v-if="dataForm.status == 0 || dataForm.status == 30" class="left"/>
        <van-row class="uploadBox" v-if="dataForm.status == 0 || dataForm.status == 30">
          <van-col :span="24">
            <van-uploader v-model="dataForm.fileList" @delete="uploaddel" :after-read="uploadRead"/>
          </van-col>
        </van-row>
      </van-cell-group>

      <van-row class="btns">
        <van-col :span="24">
          <van-button type="info" size="large" round @click="submit">确定处理</van-button>
        </van-col>
      </van-row>
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getImageStream} from '@/utils/index'

import { mapState,mapMutations } from 'vuex'
export default {
  data () {
    return {
      resultShow: false,
      typeShow: false,
      solveShow: false,
      value: '',
      detailData: {},
      resultValue: '',
      typeValue: '',
      solveValue: '',
      resultOptions:[
        { label: "无法处理", value: "0" },
        { label: "推送", value: "10" },
        { label: "处理中", value: "20" },
        { label: "处理完成", value: "30" }
      ],
      typeList:[],
      solveList: [],
      dataForm:{
        fileMessages: [],
        status:null,
        publicPlace: '',
        repairType: '',
        type:'',
        source: '',
        id: '',
        solve: '',
        solveCost: '',
        solveDes: ''
      },
      fileList:[]
    }
  },
  computed:{...mapState(['repairData'])},
  components :{
    topBar
  },
  methods: {
    submit () {
      if (this.dataForm.type == '') {return this.$toast.fail('失败:请先选择报修类别');}
      if (this.dataForm.status == '') {return this.$toast.fail('失败:请先选择处理结果');}
      if (this.dataForm.manager == '' && this.dataForm.status == 10) {return this.$toast.fail('失败:请先选择推送人员');}
      if (this.dataForm.solveDes == '' && (this.dataForm.status == 0 || this.dataForm.status == 30)) {return this.$toast.fail('失败:请先输入处理描述');}
      this.$httpApp({
          url: this.$httpApp.adornUrl('/wxapp/commonApp/repair/info/solve'),
          method: 'post',
          data: this.$httpApp.adornData({
            fileMessages: this.dataForm.fileMessages,
            id: this.dataForm.id,
            publicPlace: this.dataForm.publicPlace,
            repairType: this.dataForm.type,
            solveCost: this.dataForm.solveCost,
            solveDes: this.dataForm.solveDes,
            solveStatus: this.dataForm.status,
            solveTarget: this.dataForm.solve,
            source: this.dataForm.source
          },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.$toast.success({message:'处理成功'})
            this.$router.push('/repair')
          } else {
          this.$toast.fail(data.msg);
          }
      })
    },
    getDetail () {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/commonApp/repair/info/info'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.repairData.id
        })
      }).then(({data})=> {
          if (data.code == 0) {
            this.typeValue = data.repairInfoEntityVO.repairTypeStr
            this.dataForm.id =  data.repairInfoEntityVO.id
            this.dataForm.type = data.repairInfoEntityVO.repairType
            this.dataForm.source = data.repairInfoEntityVO.source
            this.dataForm.solveCost = data.repairInfoEntityVO.solveCost
            this.dataForm.publicPlace = data.repairInfoEntityVO.publicPlace
            this.resultOptions.forEach((item,index)=> {
              if(item.value == data.repairInfoEntityVO.solveStatus) {
                  this.resultValue = this.resultOptions[index + 1].label
                  this.dataForm.status = this.resultOptions[index + 1].value
              }
            })
          this.$toast.clear()
          } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
          }
      })
    },
    getType() {
      this.$http({
          url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
          method: 'post',
          params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'repairType'
          },false)
      }).then(({data})=> {
          if (data.code == 0) {
              this.typeList = data.dicts
              this.typeList.unshift({label:'所有', value: ''})
          } else {
          this.$toast.fail(data.msg);
          }
      })
    },
    getManager() {
      this.$http({
          url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
          method: 'post',
          params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'managerType'
          },false)
      }).then(({data})=> {
          if (data.code == 0) {
              this.solveList = data.dicts
          } else {
          this.$toast.fail(data.msg);
          }
      })
    },
        // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.dataForm.fileMessages.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", 'files/repair/temporary');
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
      }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.dataForm.fileMessages.push(data.fileMessage)
            return true
          } else {
          this.$toast.fail(data.msg);
          }
        })
      })
    },
    resultConfirm(val) {
      this.resultValue = val.label
      this.dataForm.status = val.value
      this.resultShow = false
    },
    typeConfirm(val) {
      this.typeValue = val.label
      this.dataForm.type = val.value
      this.typeShow = false
    },
    solveConfirm(val) {
      this.solveValue = val.label
      this.dataForm.solve = val.value
      this.solveShow = false
    }
  },
  created() {
    this.getDetail()
    this.getType()
    this.getManager()
  }
}
</script>
